/* Clear searchbox-input field */
function clear_text() {
    var search_input = document.getElementById("keyword");
    if (search_input.value == 'zoeken...') {
        search_input.value = "";
    }
}

/* Set default text in searchbox-input field */
function set_text() {
    var search_input = document.getElementById("keyword");
    if (search_input.value == '') {
        search_input.value = "zoeken...";
    }
}

jQuery(document).ready(function() {
    jQuery("#totop").scrollToTop({speed:1000,ease:"easeInOutQuart",start:200});

    /* FOCUS DROPDOWN MENU */
    jQuery('.hasitems').focusin(function() {
        jQuery(this).addClass('focus');
    }).focusout(function() {
        jQuery(this).removeClass('focus');
    });

    jQuery('.simloket_tabs_content .simloket_tab_content').each(function() {
        var id = jQuery(this).attr("id");
        var split = id.split("_");
        if (jQuery("#simloket_tab_"+split[3]).length == 0) {
            jQuery(this).remove();
        }
    });
});